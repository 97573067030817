<template>
  <DetailTemplate
    :custom-class="'quotation-detail detail-page'"
    v-if="getPermission('quotation:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0">
          <!-- <Status :status="customerActivated"></Status> -->

          <template v-if="detail && detail.status">
            <CustomStatus
              :status="detail.status"
              endpoint="quotation/status"
            ></CustomStatus>
          </template>
          <v-chip
            label
            :color="getColorType(detail.type)"
            text-color="white"
            class="ml-1 text-uppercase font-weight-600"
            >{{ getColorText(detail.type) }}</v-chip
          >
          <template v-if="detail.is_revised && detail.is_revised > 0">
            <v-tooltip bottom content-class="custom-bottom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  color="red"
                  outlined
                  class="text-white p-3 ml-2"
                >
                  REVISED
                </v-chip>
              </template>
              <span>Quotation is Revised</span>
            </v-tooltip>
          </template>
          <template v-if="detail.is_contract">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  color="green lighten-1"
                  class="ml-0 mr-2 white--text font-weight-600"
                  label
                >
                  CONTRACT
                </v-chip>
              </template>
              <span>Contract</span>
            </v-tooltip>
          </template>
          <template
            v-if="
              detail.quotation_revise &&
              detail.quotation_revise > 0 &&
              !detail.is_revised
            "
          >
            <v-tooltip bottom content-class="custom-bottom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  color="teal"
                  outlined
                  class="text-white p-3 ml-3"
                  link
                >
                  REVISION
                </v-chip>
              </template>
              <span>Revision of {{ detail.q_revise_barcode }}</span>
            </v-tooltip>
          </template>
          <v-chip
            v-if="
              detail &&
              detail.quotation_duplicate &&
              detail.quotation_duplicate > 0
            "
            label
            color="red"
            outlined
            class="text-white p-3 ml-2"
            >Duplicate</v-chip
          >
          <v-chip
            v-if="
              detail && detail.quotation_requote && detail.quotation_requote > 0
            "
            label
            color="red"
            outlined
            class="text-white p-3 ml-2"
            >Re-Quote</v-chip
          >
          <v-chip
            v-if="detail && detail.enquiry_barcode"
            v-on:click="viewEnquiry(detail.enquiry_id)"
            label
            color="red"
            outlined
            class="text-white p-3 ml-2"
            >{{ detail.enquiry_barcode }}</v-chip
          >
        </div>
        <div class="mt-5 px-0">
          <table width="100%">
            <!-- <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-500"
                colspan="2"
              >
                <span class="color-custom-blue font-weight-700 font-size-19">{{
                  detail.job_title
                }}</span>
              </td>
            </tr> -->
            <!--  <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Quotation #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.barcode }}</label>
                  </td>
                </tr> -->
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Quotation Information
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Quotation #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <v-chip
                  label
                  color="chip-custom-blue"
                  outlined
                  class="text-white p-3"
                  small
                  >{{ detail.barcode }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="detail && detail.q_duplicate_barcode">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Duplicate From
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <Barcode
                  dense
                  small
                  :barcode="detail.q_duplicate_barcode"
                ></Barcode>
              </td>
            </tr>
            <tr v-if="detail && detail.q_requote_barcode">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Re-Quote From
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <Barcode
                  dense
                  small
                  :barcode="detail.q_requote_barcode"
                ></Barcode>
              </td>
            </tr>
            <tr v-if="detail && detail.q_revise_barcode">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Revise From
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <Barcode
                  dense
                  small
                  :id="detail.quotation_revise"
                  :barcode="detail.q_revise_barcode"
                  :route="'quotation.detail'"
                ></Barcode>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Rate opportunity
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <v-rating
                  readonly
                  dense
                  v-model.trim="detail.rating"
                  background-color="orange lighten-3"
                  color="orange"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Customer Ref/Your Ref on PDF<!-- Customer Reference # -->
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0" v-if="detail.reference">{{
                  detail.reference
                }}</label>
                <em class="text-muted" v-else> no customer reference</em>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Internal Reference #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0" v-if="detail.your_reference">{{
                  detail.your_reference
                }}</label>
                <em class="text-muted" v-else> no internal reference</em>
              </td>
            </tr>
            <tr v-if="detail.sales_user">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Sales representatives
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  ><router-link
                    :to="{
                      name: 'admin.user.detail',
                      params: { id: detail.sales_user.id },
                    }"
                    ><v-chip
                      v-if="
                        detail &&
                        detail.sales_user &&
                        detail.sales_user.display_name
                      "
                      small
                      class="mb-1 mr-1"
                    >
                      <v-avatar left small>
                        <img
                          v-if="
                            detail.sales_user &&
                            detail.sales_user.customeProfile_logo &&
                            detail.sales_user.customeProfile_logo.file
                          "
                          :src="detail.sales_user.customeProfile_logo.file.url"
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ detail.sales_user.display_name }}</span>
                    </v-chip></router-link
                  ></label
                >
              </td>
            </tr>
            <tr v-if="detail.prepared_user">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Prepared by
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  ><router-link
                    :to="{
                      name: 'admin.user.detail',
                      params: { id: detail.prepared_user.id },
                    }"
                  >
                    <v-chip
                      v-if="
                        detail &&
                        detail.prepared_user &&
                        detail.prepared_user.display_name
                      "
                      small
                      class="mb-1 mr-1"
                    >
                      <v-avatar left small>
                        <img
                          v-if="
                            detail.prepared_user &&
                            detail.prepared_user.profile_logo &&
                            detail.prepared_user.profile_logo.file
                          "
                          :src="detail.prepared_user.profile_logo.file.url"
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ detail.prepared_user.display_name }}</span>
                    </v-chip>
                  </router-link></label
                >
              </td>
            </tr>
            <tr v-if="detail.added_at">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Created
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ formatDate(detail.added_at) }}
                </label>
              </td>
            </tr>
            <tr v-if="detail.validity_till && detail.validity_till > 0">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Validity
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ getValidityDate(detail.validity_till) }}
                  <span class="red--text">
                    ({{ detail.validity_till }}Days)
                  </span>
                </label>
              </td>
            </tr>
            <tr>
              <td valign="top" class="py-1 font-size-18 font-weight-600">
                Tag
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <template v-if="detail.tags && detail.tags.length">
                  <v-chip
                    v-for="(row, index) in detail.tags"
                    :key="index"
                    class="mr-1 mb-1"
                    text-color="white"
                    small
                    label
                    :color="row.color"
                  >
                    <span class="">
                      {{ row.text }}
                    </span>
                  </v-chip>
                </template>
                <em v-else class="text-muted"> no tags</em>
              </td>
            </tr>
            <!--  <tr v-if="detail.fax">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    FAX
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.fax }}</label>
                  </td>
                </tr>
                <tr v-if="detail.did">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    DID
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.did }}</label>
                  </td>
                </tr> -->
            <tr v-if="false && detail.approved_user">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Approved by
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  ><router-link
                    :to="{
                      name: 'admin.user.detail',
                      params: { id: detail.approved_user.id },
                    }"
                    >{{ detail.approved_user.display_name }}</router-link
                  ></label
                >
              </td>
            </tr>

            <tr v-if="detail.approved_reject_remarks">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600 d-flex"
              >
                Rejected Remark
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">{{ detail.approved_reject_remarks }}</label>
              </td>
            </tr>
            <tr v-if="detail.accepted_remarks">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600 d-flex"
              >
                Accepted Remark
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">{{ detail.accepted_remarks }}</label>
              </td>
            </tr>
            <tr v-if="false && detail.approved_date">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Accepted Date
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">{{ detail.approved_date }}</label>
              </td>
            </tr>
            <tr
              v-if="
                detail &&
                detail.ticket_multiple &&
                detail.ticket_multiple.length > 0 &&
                false
              "
            >
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Visit Id
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  <template v-for="(row, index) in detail.ticket_multiple">
                    <span :key="index">
                      <v-chip
                        label
                        :color="getColor(row.visit[0].status_text)"
                        class="text-white p-2 mr-2"
                        small
                        v-on:click="routeToVisit(row.visit[0].id)"
                      >
                        {{ row.visit[0].barcode }}
                      </v-chip>
                      <!--   <v-chip outlined color="cyan" class="text-white">
                                {{ row.visit[0].barcode }} 
                              </v-chip> -->
                    </span>
                  </template>
                </label>
              </td>
            </tr>
            <tr v-if="false && detail.is_contract">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Is Contract ?
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">{{
                  detail.is_contract ? "Yes" : "No"
                }}</label>
              </td>
            </tr>
            <tr v-if="detail.is_contract">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Contract Duration
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  >{{ formatDate(detail.contract_start) }} to
                  {{ formatDate(detail.contract_end) }} ({{
                    detail.duration_value
                  }}
                  {{ detail.duration_type }})</label
                >
              </td>
            </tr>
            <tr v-if="detail.is_contract">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                No. of Services
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">{{ detail.total_service }}</label>
              </td>
            </tr>
            <tr v-if="detail.is_contract">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Contract Remark
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0" v-if="detail.contract_remark">{{
                  detail.contract_remark
                }}</label>
                <em v-else class="text-muted"> no contract remark</em>
              </td>
            </tr>
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <span
        v-if="pageLoaded"
        class="color-custom-blue font-weight-700 font-size-20 mr-2"
        >{{ detail.job_title }}</span
      >
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end" v-if="pageLoaded">
        <template v-if="false && detail.status == 1">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_approved')"
              >
                <v-icon left>mdi-check</v-icon> Approve
              </v-btn>
            </template>
            <span>Click here to Approve</span>
          </v-tooltip>
        </template>
        <template v-if="false && detail.status == 1 && userDetails.role == 3">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_rejected')"
              >
                <v-icon left>mdi-close </v-icon> Reject
              </v-btn>
            </template>
            <span>Click here to Reject</span>
          </v-tooltip>
        </template>
        <template v-if="detail.status == 3 && !detail.is_revised">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('revise')"
              >
                <v-icon left>mdi-update</v-icon> Revise
              </v-btn>
            </template>
            <span>Click here to revise Quote</span>
          </v-tooltip>
        </template>
        <template
          v-if="detail.status != 3 && detail.status != 2 && detail.status != 9"
        >
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="green"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_accepted')"
              >
                <v-icon left>mdi-check-decagram-outline</v-icon> Accept
              </v-btn>
            </template>
            <span>Mark As Accepted</span>
          </v-tooltip>
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_rejected')"
              >
                <v-icon left>mdi-close-thick</v-icon> Reject
              </v-btn>
            </template>
            <span>Mark As Rejected</span>
          </v-tooltip>
        </template>
        <template
          v-if="detail.status != 2 && detail.status != 3 && detail.status != 5"
        >
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('edit')"
              >
                <v-icon left>mdi-pencil</v-icon> Edit
              </v-btn>
            </template>
            <span>Click here to edit Quote</span>
          </v-tooltip>
        </template>
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :disabled="statusLoading"
              class="mx-1 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          depressed
          class="mx-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <!--  <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll quotation-detail-height"
        style="position: relative"
      > -->
      <v-container fluid v-if="pageLoaded">
        <!--  <v-row v-if="pageLoaded">
            <v-col md="12"> -->
        <v-tabs
          active-class="custom-tab-active"
          v-model="quotationTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab
            v-if="false"
            class="font-size-16 font-weight-600 px-8"
            href="#invoice"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Invoices
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#visit">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#attachments">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/Attachments.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            Attachments
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            href="#pdfHistory"
            v-if="false"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            PDF History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="quotationTab">
          <v-tab-item value="other">
            <v-container fluid>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >Location Detail</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <span class="color-custom-blue font-weight-700 font-size-19">
                    <router-link
                      :to="
                        getDefaultRoute('customer.detail', {
                          params: {
                            id: detail.customer.id,
                          },
                        })
                      "
                      >{{ detail.customer.display_name }}
                    </router-link>
                  </span>
                  <v-layout class="my-4">
                    <v-flex md6 class="mr-2 custom-border-right">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">Site Location</td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <a
                              v-if="
                                detail &&
                                detail.property &&
                                detail.property.property_address
                              "
                              target="_blank"
                              :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                              >{{ detail.property.property_address }}</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="
                            detail.property_person &&
                            detail.property_person.display_name
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail.property_person.display_name }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            detail.property_person &&
                            detail.property_person.primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail.property_person.primary_phone }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            detail.property_person &&
                            detail.property_person.primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail.property_person.primary_email }}
                            <!-- <v-icon
                          v-if="detail.property_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        > -->
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                        <!--  <tr>
                      <td
                        valign="middle"
                        class="py-1 font-size-18 font-weight-600"
                      >
                        Delivery Site (Service Locaiton As Default)
                      </td>
                    </tr>
                    <tr>
                      <td
                        valign="middle"
                        class="py-1 font-size-18 font-weight-500"
                      >
                        <label class="m-0">{{ detail.delivery_site }}</label>
                      </td>
                    </tr> -->
                      </table>
                    </v-flex>
                    <v-flex md6 class="ml-2">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">Billing Address</td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <a
                              v-if="
                                detail &&
                                detail.billing &&
                                detail.billing.property_address
                              "
                              target="_blank"
                              :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                              >{{ detail.billing.property_address }}</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="
                            detail.billing_person &&
                            detail.billing_person.display_name
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail.billing_person.display_name }}
                          </td>
                        </tr>

                        <tr
                          v-if="
                            detail.billing_person &&
                            detail.billing_person.primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail.billing_person.primary_phone }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            detail.billing_person &&
                            detail.billing_person.primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{ detail.billing_person.primary_email }}
                            <!-- <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        > -->
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                      </table>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >INTERNAL NOTES &amp; ATTACHMENTS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout class="mb-4">
                    <v-flex md6 class="custom-border-right">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          detail.client_remark || '<em>No Client Notes</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                    <v-flex md6>
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="detail.admin_remark || '<em>No Admin Notes</em>'"
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >TERMS &amp; CONDITIONS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <div
                    class="w-100 custom-grey-border p-4 mb-2"
                    v-if="
                      detail &&
                      detail.term_condition_id &&
                      detail.term_conditions_title &&
                      detail.term_conditions_title.label
                    "
                  >
                    {{ detail.term_conditions_title.label }}
                  </div>
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class="mx-2 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                      more-str="read more"
                      :text="
                        detail.term_conditions ||
                        '<em>No Terms &amp; Conditions</em>'
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
                <!-- <v-card-text class="p-6 font-size-16">
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.validity">
                              {{ detail.validity }}
                            </template>
                            <template v-else>
                              <em>No Validity Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.payment">
                              {{ detail.payment }}
                            </template>
                            <template v-else>
                              <em>No Payment Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.delivery">
                              {{ detail.delivery }}
                            </template>
                            <template v-else>
                              <em>No Delivery Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.deposit">
                              {{ detail.deposit }}
                            </template>
                            <template v-else>
                              <em>No Deposit Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.warranty">
                              {{ detail.warranty }}
                            </template>
                            <template v-else>
                              <em>No Warranty Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.cancellation">
                              {{ detail.cancellation }}
                            </template>
                            <template v-else>
                              <em>No Cancellation Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                      </v-card-text> -->
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >DESCRIPTION</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class=""
                      v-if="detail && detail.description"
                      more-str="read more"
                      :text="detail.description || '<em>No Description</em>'"
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item value="line-item">
            <!--   <pre>{{ detail }}</pre> -->
            <QuotationLineItem
              :show-price="detail.show_price"
              v-on:line-item="lengthCount"
              :discount-level="
                detail.discount_type == 2 ? 'line_item' : 'transaction'
              "
              :related-id="quotationId"
              :related-type="1"
            />
            <div class="mt-4 pr-2 font-weight-600">
              <table width="100%">
                <tr>
                  <td width="66%"></td>
                  <td width="18%" align="right">Sub Total</td>
                  <td width="18%" align="right">
                    {{ formatMoney(detail.sub_total) }}
                  </td>
                </tr>
                <tr v-if="detail.discount_type == 1">
                  <td></td>
                  <td align="right">
                    Discount
                    <span v-if="detail.discount_value_type == 1"
                      >({{ formatMoney(detail.discount_value) }})</span
                    >
                    <span v-if="detail.discount_value_type == 2"
                      >({{ formatNumber(detail.discount_value) }}%)</span
                    >
                  </td>
                  <td align="right">
                    {{ formatMoney(detail.discount_amount) }}
                  </td>
                </tr>
                <tr v-if="detail.tax_applied">
                  <td></td>
                  <td align="right">Tax [GST {{ detail.tax_value }}%]</td>
                  <td align="right">
                    {{ formatMoney(detail.tax_amount) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td align="right">Adjustment</td>
                  <td align="right">
                    {{ formatMoney(detail.adjustment) }}
                  </td>
                </tr>
                <tr class="font-size-19">
                  <td></td>
                  <td align="right">Grand Total</td>
                  <td align="right">{{ formatMoney(detail.total) }}</td>
                </tr>
              </table>
            </div>
            <!-- <QuotationLineItem
                    is-quotation
                    show-detail
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                    :discount-value="detail.discount_value"
                    :currency="detail.currency"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :tax-value="detail.tax_value"
                    :adjustment-value="detail.adjustment"
                    v-on:load:content="getQuotation()"
                  ></QuotationLineItem> -->
          </v-tab-item>
          <v-tab-item value="invoice">
            <InternalInvoice
              type="quotation"
              :type-id="quotationId"
            ></InternalInvoice>
          </v-tab-item>
          <v-tab-item value="visit">
            <InternalVisit
              is-admin
              visit-type="all"
              internal
              :quotation-id="detail.id"
              :customer-id="detail.customer.id"
              :detail-route="'visit.detail'"
              :entity-id="detail.entity_id"
            ></InternalVisit>
          </v-tab-item>
          <v-tab-item value="attachments">
            <AdditionalDocuments
              :type-id="detail.id"
              class="mx-4"
              type-text="Quotation Attachments"
              :type="2"
              :extensive="false"
            ></AdditionalDocuments>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="quotation"
              :type_id="detail.id"
            ></InternalHistoryDetail>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('history:view')"
            :value="'pdfHistory'"
          >
            <PDFHistoryDetail
              type="quotation"
              :type_id="detail.id"
              v-on:success="getQuotation()"
            >
            </PDFHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
        <!-- </v-col>
          </v-row> -->
      </v-container>
      <!--  </perfect-scrollbar> -->
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="quotation"
        v-on:close="mail_dialog = false"
        :type-id="quotationId"
        v-on:success="getQuotation()"
      ></SendMail>
      <Dialog :common-dialog="confirm_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to
                <span class="font-weight-700">{{ get_confirm_title() }}</span
                >?
              </p>
              <v-layout
                class="my-4"
                v-if="
                  confirm_type == 'mark_as_approved' ||
                  confirm_type == 'mark_as_rejected' ||
                  confirm_type == 'mark_as_accepted'
                "
              >
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="actionData.remark"
                    placeholder="Remark"
                    solo
                    flat
                    row-height="25"
                    counter="200"
                  />
                </v-flex>
              </v-layout>
              <v-layout
                class="mt-4"
                v-if="
                  confirm_type == 'mark_as_accepted' ||
                  confirm_type == 'mark_as_rejected'
                "
              >
                <v-flex md12>
                  <label class="font-weight-500 font-size-16">Attachment</label>

                  <file-upload v-model="actionData.attachments" :maxLimit="5">
                  </file-upload>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="confirm_submit()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="visit_count_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Visit Id Listing On Same Quotation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <div class="font-weight-600 font-size-19">
                <!--    {{detail.ticket_multiple}} -->
                <table>
                  <th>Visit #</th>
                  <th>Date</th>
                  <!-- <th>Date</th> -->
                  <tr>
                    <td>
                      <template v-for="(row, index) in detail.ticket_multiple">
                        <span :key="index">
                          <v-chip
                            label
                            :color="getColor(row.visit[0].status_text)"
                            class="text-white p-2 mb-2"
                            small
                          >
                            {{ row.visit[0].barcode }}
                          </v-chip>
                          <br />
                        </span>
                      </template>
                    </td>
                    <td>
                      <template v-for="(row, index) in detail.ticket_multiple">
                        <span :key="index">
                          <v-chip
                            v-if="row && row.visit && row.visit[0]"
                            label
                            color="cyan"
                            class="text-white p-2 mb-2"
                            small
                          >
                            {{ formatDate(row.visit[0].finished_at, "") }}
                          </v-chip>
                          <br />
                        </span>
                      </template>
                    </td>
                    <!-- <td>
                        <template v-for="(row, index) in detail.ticket_multiple">
                          <span :key="index">
                            <v-chip v-if="row && row.visit && row.visit[0] && row.visit[0].teams && row.visit[0].teams.barcode" label color="cyan" class="text-white p-2" small>
                              {{row.visit[0].teams.barcode}}
                            </v-chip>
                          </span>
                        </template>
                      </td> -->
                  </tr>
                  <!--  <tr>
                  
                      <template v-for="(row, index) in detail.ticket_multiple">
                        
                        <span :key="index">
                          <v-chip v-if="row && row.visit && row.visit[0] && row.visit[0].teams && row.visit[0].teams.team_name" label color="cyan" class="text-white p-2 ml-2 mb-2" small>
                            {{ row.visit[0].teams.team_name}}
                          </v-chip>

                        </span>
                      </template>
                  
                 </tr> -->
                </table>
              </div>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="visit_count_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="(confirm = true), routeToJob()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="download_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Download Quotation </template>
        <template v-slot:body>
          <v-container class="px-10" fluid>
            <v-col md="12">
              <v-autocomplete
                dense
                color="cyan"
                filled
                id="prepared-by"
                :items="templateList"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-model.trim="download_template"
                label="Download Template"
                solo
                flat
                item-color="cyan"
                item-text="title"
                item-value="value"
                hide-details
                v-on:change="updatePreparedBy()"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'No Template Found.'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="12" v-if="download_template == 'HYC_Pn_Inst'">
              <label class="font-weight-700 font-size-16"
                >Installation Clause</label
              >
              <!-- <v-textarea
                  auto-grow
                  dense
                  filled
                  flat
                  v-model="profeinstaservice.profe_insta_service"
                  label="Professional Services and Installation Services"
                  solo
                  color="cyan"
                  hide-details
                  row-height="10"
                  
                ></v-textarea> -->
              <editor
                :disabled="formLoading"
                v-model="profeinstaservice.profe_insta_service"
              />
            </v-col>
            <v-col>
              <label class="font-weight-700 font-size-16">Download Date</label>
              <v-menu
                v-model="DatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Download Date"
                    hide-details
                    solo
                    :rules="[
                      validateRules.required(download_date, 'Download Date'),
                    ]"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedDownloadDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="download_date"
                  color="cyan"
                  :min="todayDate"
                  v-on:input="DatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <label class="font-weight-700 font-size-16">Valid Till</label>
              <v-menu
                v-model="ValidDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Valid Till"
                    hide-details
                    solo
                    :rules="[validateRules.required(valid_till, 'Valid Till')]"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedValidDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="valid_till"
                  color="cyan"
                  :min="todayDate"
                  v-on:input="ValidDatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="download_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Close
          </v-btn>
          <v-btn
            v-on:click="download_submit()"
            :disabled="confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Download
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

/* import QuotationLineItem from "@/view/pages/partials/Line-Item-Detail-New"; */
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

import SendMail from "@/view/pages/partials/Send-Mail";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import {
  GET,
  /* QUERY, */ PATCH,
  PUT,
} from "@/core/services/store/request.module";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import PDFHistoryDetail from "@/view/pages/partials/Detail/PDF-History-Detail.vue";
import JwtService from "@/core/services/jwt.service";
import Dialog from "@/view/pages/partials/Dialog";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import QuotationLineItem from "@/view/components/VisitLineItem.vue";
import FileUpload from "@/view/components/FileUpload";
import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      formLoading: false,
      tab: null,
      quotationId: 0,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      invoiceLoading: false,
      pageLoading: false,
      todayDate: null,
      DatePicker: false,
      ValidDatePicker: false,
      signature: 0,
      confirm_loading: false,
      visit_count_dialog: false,
      download_template: "HYC_Pn_Inst",
      download_dialog: false,
      confirm_dialog: false,
      download_date: moment().format("YYYY-MM-DD"),
      //valid_till: moment().format("YYYY-MM-DD"),
      valid_till: moment().add(14, "days").format("YYYY-MM-DD"),
      confirm_type: null,
      quotationTab: "other",
      moreActions: [],
      detail: {},
      db_approval: {},
      dbLineItems: [],
      dbEquipments: [],
      confirm: false,
      templateList: [],
      actionData: {
        remark: null,
        attachments: [],
        status: null,
      },
      userDetails: new Object(),
      attachments: [],
      profeinstaservice: {
        profe_insta_service: null,
        quotation: null,
      },
    };
  },
  methods: {
    updateAttachment(param) {
      this.attachments = param;
    },
    lengthCount(param) {
      this.itemCount = param;
    },
    viewEnquiry(id) {
      this.$router.push(
        this.getDefaultRoute("enquiry.detail", {
          params: { id: id },
        })
      );
    },
    pageReload() {
      window.location.reload();
    },
    getValidityDate(days) {
      if (this.detail.added_at) {
        let date = moment(this.detail.added_at, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        let validity = moment(date, "YYYY-MM-DD")
          .add(days, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    get_confirm_title() {
      if (this.confirm_type == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirm_type == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirm_type == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirm_type == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirm_type == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirm_type == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    getColor(data) {
      if (data == "Open") {
        return "blue";
      } else if (data == "In-Progress") {
        return "yellow";
      } else if (data == "Hold") {
        return "red";
      } else if (data == "Completed") {
        return "green";
      } else if (data == "Cancelled") {
        return "red";
      } else if (data == "Hold & Unassigned") {
        return "cyan";
      }
    },
    getColorType(data) {
      if (data == 1) {
        return "green darken-3";
      } else if (data == 2) {
        return "orange darken-3";
      } else if (data == 3) {
        return "red darken-3";
      }
    },
    getColorText(data) {
      if (data == 1) {
        return "New";
      } else if (data == 2) {
        return "Maintenance";
      } else if (data == 3) {
        return "General";
      }
    },
    routeToVisit(id) {
      this.$router.push({
        name: "admin.visit.detail",
        params: { id },
      });
    },
    confirm_submit() {
      const _this = this;
      const validateStatus = _this.$refs.statusForm.validate();
      const formErrors = _this.validateForm(_this.$refs.statusForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      if (!validateStatus) {
        return false;
      }
      _this.confirm_loading = true;
      switch (this.confirm_type) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
      }
    },
    async download_submit() {
      if (this.download_template && this.download_dialog) {
        await this.ProfesnalInstaService();
      }
    },
    downloadPDFNew() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/pdf/download/${this.download_template}?token=${token}&date=${this.download_date}&valid_till=${this.valid_till}`;
      // console.log(url);
      window.open(url, "_blank");
      this.profeinstaservice.profe_insta_service = this.profeinstaservice
        .profe_insta_service
        ? this.profeinstaservice.profe_insta_service
        : this.detail.profe_insta_service;
      this.download_dialog = false;
    },
    getQuotationSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.signature = data.signature.value;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateMoreAction(type) {
      switch (type) {
        case "edit":
          this.editQuotation();
          break;
        case "revise":
          this.reviseQuotation();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "re_quote":
          this.requoteQuotation();
          break;
        case "mark_as_closed":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_cancelled";
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;
        case "mark_as_pending":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_accepted";
          break;
        case "mark_as_approved":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_approved";
          break;
        case "download_pdf":
          /* this.download_dialog = true; */
          //this.downloadPDF();
          this.downloadPDFNew();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.quotationId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateStatus(status) {
      if (this.statusLoading) {
        return false;
      }
      /*   this.actionData.attachments = this.attachments; */
      this.actionData.status = status;
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "quotation/" + this.quotationId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getQuotation();
          this.confirm_loading = false;
          this.confirm_dialog = false;
          this.confirm_type = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
          this.confirm_loading = false;
        });
    },
    ProfesnalInstaService() {
      const _this = this;
      // _this.pageLoading = true;
      if (_this.download_template == "HYC_Pn_Inst") {
        _this.$store
          .dispatch(PUT, {
            url: "professional-service/" + this.quotationId,
            data: {
              profe_insta_service: _this.profeinstaservice.profe_insta_service,
              quotation: _this.quotationId,
            },
          })
          .then(() => {
            this.downloadPDFNew();
            PaginationEventBus.$emit("histories-download-refresh", true);
          });
      } else {
        const { id } = this.detail;
        const token = JwtService.getToken();
        const url = `${this.$apiURL}quotation/${id}/pdf/download/${this.download_template}?token=${token}&date=${this.download_date}&valid_till=${this.valid_till}`;
        // console.log(url);
        window.open(url, "_blank");
        this.profeinstaservice.profe_insta_service =
          this.detail.profe_insta_service;
        this.download_dialog = false;

        PaginationEventBus.$emit("histories-download-refresh", true);
      }
    },

    downloadPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/print?token=${token}`;
      window.open(url, "_blank");
    },
    routeToJob() {
      if (this.detail.ticket_multiple_count > 1) {
        this.visit_count_dialog = true;
        if (this.confirm == false) {
          return false;
        }
      }
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            quotation: this.quotationId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            quotation: this.quotationId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { duplicate: this.quotationId },
        })
      );
    },
    reviseQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { revise: this.quotationId },
        })
      );
    },
    requoteQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { requote: this.quotationId },
        })
      );
    },
    /*  editQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.update", {
          params: {
            id: this.quotationId,
          },
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
          },
        })
      );
    }, */
    editQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { edit: this.detail?.id },
        })
      );
    },
    getQuotation() {
      this.$store
        .dispatch(GET, { url: "quotation/" + this.quotationId })
        .then(({ data }) => {
          this.detail = data;
          this.profeinstaservice.profe_insta_service =
            this.detail.profe_insta_service;
          this.db_approval = data.db_approval;
          this.moreActions = data.more_actions;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
  },
  created() {
    // console.log(this.$route);

    this.quotationId = this.lodash.toSafeInteger(this.$route.params.id);
    this.quotationTab = this.$route.query.tab;

    // console.log(this.quotationTab, "this.quotationTab");

    this.templateList = [
      /* { title: "HYC Pn Rm", value: "HYC_Pn_Rm" },
      { title: "HYC Rm", value: "HYC_Rm" },
      { title: "HYC Rm Inst", value: "HYC_Rm_Inst" }, */
      { title: "HYC Pn Inst", value: "HYC_Pn_Inst" },
      { title: "HYC Pn None", value: "HYC_Pn_None" },
    ];
  },
  mounted() {
    this.getQuotation();
    this.getQuotationSetting();
    this.userDetails = JwtService.currentUser();
    //console.log(this.userDetails, "hello");
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
    formattedDownloadDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.download_date).format(Config.dateFormat);
      }
      return this.download_date;
    },
    formattedValidDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.valid_till).format(Config.dateFormat);
      }
      return this.valid_till;
    },
  },
  components: {
    CustomStatus,
    DetailTemplate,
    InternalVisit,
    SendMail,
    Dialog,
    AdditionalDocuments,
    "file-upload": FileUpload,
    InternalInvoice,
    TextAreaField,
    Barcode,
    QuotationLineItem,
    InternalHistoryDetail,
    PDFHistoryDetail,
    editor: TinyMCE,
  },
};
</script>
